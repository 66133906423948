import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";

export interface IntegrationTabProps {
  clioSyncing: boolean;
  xeroSyncing: boolean;
  ppSyncing: boolean;
  disconnectDisabled: boolean;
  xeroDisconnect?: () => void;
  handleRefresh: () => void;
  handleDelete: () => void;
  handleClick: () => void;
  image: any;
  uri: string;
  integrationType: "XERO" | "CLIO" | "PP";
  handlePopup: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  delete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.error.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.background.paper,
    },
  },
  buttonRefresh: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.background.paper,
    },
  },
  buttonClio: {
    padding: theme.spacing(1, 5),
    fontSize: "0.8em",
    textTransform: "none",
    fontFamily: "Open Sans",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      color: "#0075BB",
      borderColor: "#0075BB",
      borderStyle: "solid",
      borderWidth: "1px",
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
    backgroundColor: "#0075BB",
  },
  buttonXero: {
    padding: theme.spacing(1, 5),
    textTransform: "none",
    fontFamily: "Open Sans",
    fontSize: "0.8em",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      color: "#00B8E3",
      borderColor: "#00B8E3",
      borderStyle: "solid",
      borderWidth: "1px",
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
    backgroundColor: "#00B8E3",
  },
  titleText: {
    display: "block",
    fontSize: "1.4em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2em",
      fontWeight: 600,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  bodyText: {
    fontSize: "1em",
    display: "block",
    fontFamily: "Open Sans",
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  connected: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontFamily: "Open Sans",
    fontSize: "0.9em",
    borderColor: theme.palette.secondary.main,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.spacing(1) / 2,
  },
  disconnected: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontFamily: "Open Sans",
    fontSize: "0.9em",
    borderColor: theme.palette.text.secondary,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.spacing(1) / 2,
  },
}));

const useStyles1 = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: "1.6em",
    fontFamily: "Open Sans",
    paddingTop: theme.spacing(1),
  },
}));

const useStyles2 = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: "Open Sans",
    fontSize: "1.2em",
  },
}));

const useStyles3 = makeStyles((theme: Theme) => ({
  typography: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const Header = () => {
  const classes = useStyles1();
  return <span className={classes.heading}>Integrations</span>;
};

const Title = ({ title }) => {
  const classes = useStyles2();
  return (
    <Grid item xs={2}>
      <span className={classes.title}>{title}</span>
    </Grid>
  );
};
const ErrorText = ({ children }) => {
  const classes = useStyles3();
  return <Typography className={classes.typography}>{children}</Typography>;
};

const IntegrationTab: React.FC<IntegrationTabProps> = ({
  handleClick,
  handleRefresh,
  handleDelete,
  clioSyncing,
  xeroSyncing,
  integrationType,
  ppSyncing,
  handlePopup,
  xeroDisconnect,
  image,
  uri,
}) => {
  const classes = useStyles();
  const {
    generalStates: {
      activeFirm,
      user: { userType },
    },
    currentAppState: {
      clioHasDuplicate,
      status: { code, clioStatus, ppStatus, xeroStatus },
    },
  } = useAuth();
  const theme = useTheme();
  const [lastSynced, setLastSynced] = useState<string>("");
  const buttonFullWidth = useMediaQuery(theme.breakpoints.down("sm"));
  //Retreive integration Object from global state, to check if object exists; Integration Type passed as a string prop
  const integrationObject =
    integrationType === "XERO"
      ? activeFirm?.firm?.xero
      : integrationType === "CLIO"
      ? activeFirm?.firm?.clio
      : activeFirm?.firm?.practicePanther;
  const clioDuplicate =
    integrationType === "CLIO" || integrationType === "PP"
      ? clioHasDuplicate
      : false;
  useEffect(() => {
    setLastSynced(
      integrationObject !== null &&
        typeof integrationObject !== "undefined" &&
        integrationObject.last_synced
        ? integrationObject.sync_status === "first_in_progress" &&
          integrationObject.error_log == null
          ? "Syncing in progress"
          : moment(integrationObject.last_synced).format(
              "MMMM Do YYYY, h:mm:ss a"
            )
        : "Not Synced yet"
    );
  }, [integrationObject]);
  return (
    <Grid
      container
      item
      justifyContent={"space-between"}
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={2}>
        <img
          height={integrationType === "CLIO" ? 40 : 80}
          src={image}
          alt={integrationType}
        />
        {integrationType === "XERO" && (
          <span className={classes.bodyText}>
            {integrationObject?.tenant_name || ""}
          </span>
        )}
      </Grid>
      <Grid item xs={2}>
        {integrationObject &&
        integrationObject.sync_status !== "disconnected" &&
        !integrationObject.auth_bounced &&
        integrationObject.error_log == null &&
        !clioDuplicate ? (
          <span className={classes.connected}>Connected</span>
        ) : (
          <span className={classes.disconnected}>Disconnected</span>
        )}
      </Grid>
      <Grid item xs={2}>
        <span className={classes.bodyText}>{lastSynced}</span>
      </Grid>

      <Grid item xs={2}>
        <Button
          disabled={
            integrationType === "XERO"
              ? false
              : integrationType === "CLIO"
              ? clioStatus == 2 || clioStatus == 1 || clioSyncing
              : ppSyncing || ppStatus == 2 || ppStatus == 1
          }
          size="large"
          onClick={
            ((integrationType === "XERO" || integrationType === "PP") &&
              clioStatus == 2) ||
            ((integrationType === "CLIO" || integrationType === "PP") &&
              xeroStatus == 2) ||
            ((integrationType === "CLIO" || integrationType === "XERO") &&
              ppStatus == 2)
              ? handlePopup
              : (integrationObject?.sync_status === "completed" ||
                  integrationObject?.sync_status === "first_pending") &&
                integrationType === "XERO"
              ? xeroDisconnect
              : handleClick
          }
          href={
            (!integrationObject?.auth_bounced &&
              (integrationObject?.sync_status == "completed" ||
                integrationObject?.sync_status == "first_pending")) ||
            ((integrationType === "XERO" || integrationType === "PP") &&
              clioStatus == 2) ||
            ((integrationType === "CLIO" || integrationType === "PP") &&
              xeroStatus == 2) ||
            ((integrationType === "CLIO" || integrationType === "XERO") &&
              ppStatus == 2)
              ? "#"
              : uri
          }
          variant="contained"
          color="primary"
          fullWidth={buttonFullWidth}
          classes={{
            root:
              integrationType === "XERO"
                ? classes.buttonXero
                : classes.buttonClio,
          }}
        >
          {!clioDuplicate &&
          integrationType === "XERO" &&
          integrationObject &&
          integrationObject?.sync_status !== "disconnected"
            ? "Disconnect"
            : integrationObject?.sync_status == "disconnected" &&
              integrationType == "XERO"
            ? `Reconnect`
            : `Connect`}
        </Button>
      </Grid>
      <Grid item xs={userType == "SUPER_ADMIN" ? 1 : 2}>
        <IconButton
          onClick={handleRefresh}
          classes={{
            root: classes.buttonRefresh,
          }}
          disabled={
            (integrationType === "XERO"
              ? xeroSyncing || integrationObject?.sync_status == "disconnected"
              : clioSyncing) ||
            (integrationObject && !clioDuplicate)
              ? false
              : true
          }
        >
          <CachedIcon />
        </IconButton>
      </Grid>
      {userType == "SUPER_ADMIN" && (
        <Grid item xs={1}>
          <IconButton
            onClick={handleDelete}
            classes={{
              root: classes.delete,
            }}
            disabled={
              integrationObject == null ||
              clioSyncing ||
              xeroSyncing ||
              ppSyncing ||
              integrationObject?.sync_status == "first_in_progress"
            }
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export { IntegrationTab, Header, Title, ErrorText };
